import { FormSettings } from "../model/common/FormSettings";
import { FormSettingsType } from "../model/common/FormSettingsType";
import AdyenProviderSettings from "../model/common/providers/adyen/AdyenProviderSettings";
import { AdyenDropinContainer } from "./adyen-dropin-container";
import { PaymentsSummary } from "./payments-summary";
import { renderToString } from "jsx-async-runtime";
import {
  API_BASE_ADDRESS,
  BRAND_HEADER,
  CULTURE_HEADER,
  GET_METHOD,
  IS_TEST_ENVIRONMENT,
  LIVE,
  PG_BRAND,
  PG_CULTURE,
  SESSION_ID,
  TEST,
  ADYEN_DROPIN_CONTAINER,
  HOST_CONTAINER,
  PAYMENTS_SUMMARY,
  PAYMENTS_WEB_COMPONENT,
  OMIT,
  INCLUDE,
} from "../constants";
import HttpRequest from "../utils/HttpRequest";

class PaymentsWebComponent extends HTMLElement {
  apiBaseUrl: string;
  sessionId: string;
  isTestEnvironment: boolean;
  brand: string;
  culture: string;
  constructor() {
    super();
  }

  async getFormSettings(): Promise<FormSettings> {
    const headers = new Headers();
    headers.append(BRAND_HEADER, this.brand);
    headers.append(CULTURE_HEADER, this.culture);

    let credentials = OMIT as RequestCredentials;
    if (this.isTestEnvironment) {
      credentials = INCLUDE as RequestCredentials;
    }
    const url = `${this.apiBaseUrl}/formsettings/${this.sessionId}`;
    const response = await HttpRequest.get({
      url,
      credentials,
      brand: this.brand,
      culture: this.culture,
    });
    return response;
  }

  async connectedCallback() {
    const html = <div id={HOST_CONTAINER}></div>;
    this.innerHTML = await renderToString(html);

    this.sessionId = this.getAttribute(SESSION_ID);
    this.brand = this.getAttribute(PG_BRAND);
    this.culture = this.getAttribute(PG_CULTURE);
    this.apiBaseUrl = this.getAttribute(API_BASE_ADDRESS);
    this.isTestEnvironment = this.getAttribute(IS_TEST_ENVIRONMENT) === "true";

    let environment = TEST;
    if (!this.isTestEnvironment) {
      environment = LIVE;
    }

    const formSettings = await this.getFormSettings();
    const clientKey = (formSettings.providerSettings as AdyenProviderSettings)
      .clientKey;

    const adyenDropinConfiguration = {
      clientKey: clientKey,
      environment: environment,
      sessionId: this.sessionId,
      brand: this.brand,
      culture: this.culture,
      apiBaseUrl: this.apiBaseUrl,
      ...formSettings,
    };

    const adyenDropinContainer = document.createElement(
      ADYEN_DROPIN_CONTAINER
    ) as AdyenDropinContainer;
    adyenDropinContainer.configuration = adyenDropinConfiguration;
    document.getElementById(HOST_CONTAINER).appendChild(adyenDropinContainer);

    if (formSettings.type === FormSettingsType.Payment) {
      const paymentSummary = document.createElement(
        PAYMENTS_SUMMARY
      ) as PaymentsSummary;
      paymentSummary.formSettings = formSettings;
      document
        .getElementById(HOST_CONTAINER)
        .insertBefore(
          paymentSummary,
          document.querySelector(ADYEN_DROPIN_CONTAINER)
        );
    }
  }
}

if (!customElements.get(PAYMENTS_WEB_COMPONENT)) {
  customElements.define(PAYMENTS_WEB_COMPONENT, PaymentsWebComponent);
}
