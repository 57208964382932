import { BRAND_HEADER, CULTURE_HEADER } from "../constants";

interface RequestConfig {
  method?: "GET" | "POST" | "PUT" | "PATCH" | "DELETE";
  url: string;
  headers?: Record<string, string>;
  body?: any;
  queryParams?: Record<string, string>;
  credentials?: RequestCredentials;
  timeout?: number;
  brand: string;
  culture: string;
}

class HttpRequest {
  static getDefaultHeaders(
    brand: string,
    culture: string
  ): Record<string, string> {
    return {
      [BRAND_HEADER]: brand,
      [CULTURE_HEADER]: culture,
      Accept: "application/json",
      "Content-Type": "application/json",
    };
  }

  static async request(config: RequestConfig): Promise<any> {
    const {
      method = "GET",
      url,
      headers = {},
      body = null,
      queryParams = {},
      credentials = "same-origin",
      timeout = 5000,
      brand,
      culture,
    } = config;

    const fullUrl = new URL(url);
    Object.entries(queryParams).forEach(([key, value]) => {
      fullUrl.searchParams.append(key, value);
    });

    const options: RequestInit = {
      method,
      headers: { ...this.getDefaultHeaders(brand, culture), ...headers },
      credentials,
    };

    if (body) {
      options.body = typeof body === "string" ? body : JSON.stringify(body);
    }

    try {
      const controller = new AbortController();
      const timeoutId = setTimeout(() => controller.abort(), timeout);

      options.signal = controller.signal;

      const response = await fetch(fullUrl.toString(), options);
      clearTimeout(timeoutId);

      if (!response.ok) {
        const errorBody = await response.text();
        throw new HttpError(response.status, response.statusText, errorBody);
      }

      const contentType = response.headers.get("content-type");
      if (contentType && contentType.includes("application/json")) {
        return await response.json();
      } else {
        return await response.text();
      }
    } catch (error) {
      if (error instanceof Error && error.name === "AbortError") {
        throw new Error("Request timed out");
      }
      console.error("Error making HTTP request:", error);
      throw error;
    }
  }

  static get(config: Omit<RequestConfig, "method">): Promise<any> {
    return this.request({ ...config, method: "GET" });
  }

  static post(config: Omit<RequestConfig, "method">): Promise<any> {
    return this.request({ ...config, method: "POST" });
  }

  static put(config: Omit<RequestConfig, "method">): Promise<any> {
    return this.request({ ...config, method: "PUT" });
  }

  static patch(config: Omit<RequestConfig, "method">): Promise<any> {
    return this.request({ ...config, method: "PATCH" });
  }

  static delete(config: Omit<RequestConfig, "method">): Promise<any> {
    return this.request({ ...config, method: "DELETE" });
  }
}

class HttpError extends Error {
  status: number;
  statusText: string;
  body: string;

  constructor(status: number, statusText: string, body: string) {
    super(`HTTP Error ${status}: ${statusText}`);
    this.status = status;
    this.statusText = statusText;
    this.body = body;
  }
}

export default HttpRequest;
