import {
  AdyenCheckout,
  Dropin,
  DropinConfiguration,
  CoreConfiguration,
  PaymentMethodsResponse,
  PaymentData,
} from "@adyen/adyen-web/auto";
import { renderToString } from "jsx-async-runtime";
import {
  ADYEN_DROPIN_CONTAINER,
  PAYMENT_ERRORED,
  PAYMENT_SUCCEEDED,
} from "../constants";
import HttpRequest from "../utils/HttpRequest";

export class AdyenDropinContainer extends HTMLElement {
  private _configuration: any;

  constructor() {
    super();
  }

  get amount() {
    // Get the amount to show the user, this is for display purposes only
    const originalAmount =
      this.configuration.paymentTimelineSummary.overviewLines[0].amount;
    if (originalAmount && originalAmount.value > 0) {
      return { ...originalAmount, value: originalAmount.value * 100 };
    }
    return originalAmount;
  }

  get configuration() {
    return this._configuration;
  }

  set configuration(value) {
    value.countryCode = value.culture.substring(3);
    this._configuration = value;
  }

  async getPaymentMethods() {
    const url = `${this.configuration.apiBaseUrl}/adyenprocessor/getpaymentmethods`;

    const response = await HttpRequest.get({
      url,
      queryParams: {
        sessionId: this.configuration.sessionId,
      },
      brand: this.configuration.brand,
      culture: this.configuration.culture,
    });
    return response;
  }

  coreConfiguration(paymentMethods: PaymentMethodsResponse) {
    let config: CoreConfiguration = {
      clientKey: this.configuration.clientKey,
      environment: this.configuration.environment,
      locale: this.configuration.culture,
      countryCode: this.configuration.countryCode,
      paymentMethodsResponse: paymentMethods,
      onSubmit: this.handleOnSubmit.bind(this),
      onAdditionalDetails: this.handleAdditionalDetails.bind(this),
      amount: this.amount,
    };
    return config;
  }

  dropinConfiguration() {
    let dropinConfiguration: DropinConfiguration = {
      paymentMethodsConfiguration: {
        card: {
          hasHolderName: true,
          holderNameRequired: true,
          hideCVC: false,
          billingAddressRequired: false,
          challengeWindowSize: "05", // ['100%', '100%']
        },
      },
    };
    return dropinConfiguration;
  }

  async handleOnSubmit(state, _dropin, actions) {
    try {
      const { action, order, resultCode, donationToken, authorisationResult } =
        await this.makePayment(state.data);

      const result = resultCode || authorisationResult;

      if (!result) actions.reject();

      actions.resolve({
        result,
        action,
        order,
        donationToken,
      });

      if (!action) {
        this.handleResponse(result);
      }
    } catch (error) {
      actions.reject();
    }
  }

  async handleAdditionalDetails(state, _dropin, actions) {
    try {
      const { action, order, resultCode, donationToken, authorisationResult } =
        await this.makeDetailsCall(state.data);

      const result = resultCode || authorisationResult;

      if (!result) actions.reject();

      actions.resolve({
        result,
        action,
        order,
        donationToken,
      });

      if (!action) {
        this.handleResponse(result);
      }
    } catch (error) {
      actions.reject();
    }
  }

  async makePayment(stateData: PaymentData) {
    const url = `${this.configuration.apiBaseUrl}/adyenprocessor/makepayment`;

    const response = await HttpRequest.post({
      url,
      body: JSON.stringify({
        payload: JSON.stringify(stateData),
        sessionId: this.configuration.sessionId,
        enableRecurring: false,
      }),
      brand: this.configuration.brand,
      culture: this.configuration.culture,
    });
    return response;
  }

  async makeDetailsCall(stateData: {
    details: any;
    paymentData?: string;
    sessionData?: string;
  }) {
    const url = `${this.configuration.apiBaseUrl}/adyenprocessor/completethreeds2`;

    const response = await HttpRequest.post({
      url,
      body: JSON.stringify({
        authenticationResult: stateData.details.threeDSResult,
        sessionId: this.configuration.sessionId,
      }),
      brand: this.configuration.brand,
      culture: this.configuration.culture,
    });
    return response;
  }

  raisePaymentSuccess() {
    const event = new Event(PAYMENT_SUCCEEDED);
    window.dispatchEvent(event);
  }

  raisePaymentErrored(response) {
    const event = new CustomEvent(PAYMENT_ERRORED, { detail: response });
    window.dispatchEvent(event);
  }

  handleResponse(resultCode) {
    if (resultCode === "Refused") {
      this.raisePaymentErrored(resultCode);
    } else {
      this.raisePaymentSuccess();
    }
  }

  async setup() {
    try {
      const paymentMethods = await this.getPaymentMethods();
      const coreConfiguration = this.coreConfiguration(paymentMethods);
      const dropinConfiguration = this.dropinConfiguration();
      const checkout = await AdyenCheckout(coreConfiguration);
      const dropin = new Dropin(checkout, dropinConfiguration).mount(
        `#${ADYEN_DROPIN_CONTAINER}`
      );
    } catch (error) {
      console.error("Error setting up Adyen Dropin", error);
    }
  }

  async connectedCallback() {
    const html = <div id={ADYEN_DROPIN_CONTAINER}></div>;
    this.innerHTML = await renderToString(html);
    this.setup();
  }
}

if (!customElements.get(ADYEN_DROPIN_CONTAINER)) {
  customElements.define(ADYEN_DROPIN_CONTAINER, AdyenDropinContainer);
}
